jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
      if (ns.includes("noPreventDefault")) {
        this.addEventListener("touchstart", handle, { passive: false });
      } else {
        this.addEventListener("touchstart", handle, { passive: true });
      }
    }
  };
  
  jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
      if (ns.includes("noPreventDefault")) {
        this.addEventListener("touchmove", handle, { passive: false });
      } else {
        this.addEventListener("touchmove", handle, { passive: true });
      }
    }
  };
  
  $("a[href$='#start']").on('click', function () {
    $([document.documentElement, document.body]).animate({
      scrollTop: $("#start").offset().top
    }, 1000);
  });

/**** Main menu ****/

$('.hamburger-wrapper').on('click', function () {
    $(this).toggleClass("opened");
    $('.navbar-menu').toggleClass("opened");
    $('body').toggleClass('overflow');

    if($('.level2-wrapper').hasClass('open')) {
        $('.level2-wrapper').removeClass('open');
    };
    });

    $('.show-more').on('click', function() {
        $(this).closest('li.level1').children('.level2-wrapper').toggleClass('open');
    });

    $('.mobile-back').on('click', function() {
    if($(this).closest('.level2-wrapper').hasClass('open')) {
        $('.level2-wrapper').removeClass('open');
    };
});
  
/**** Main menu end ****/

/**** Tabs ****/

$("a.collapse").on('click', function () {
    var tabPane = $(this).closest(".tab-pane");
    tabPane.toggleClass("opened");
    $(".tab-pane").not(tabPane).removeClass('opened');
});
  
$("a.nav-link").on('click', function () {
var navItem = $(this).closest(".nav-item");

if (!navItem.hasClass("active")) {
    navItem.toggleClass("active");
    $(".nav-item").not(navItem).removeClass('active');
}
});
  
/**** Tabs end ****/

/**** SplideJS start ****/

if ($('#brand-logos').length > 0) {
  var splide = new Splide('#brand-logos', {
    gap: 48,
    type: 'loop',
    drag: 'free',
    focus: 'center',
    perPage: 10,
    pagination: false,
    arrows: false,
    breakpoints: {
      992: {
        perPage: 6,
      },
      576: {
        perPage: 3,

      },
    },
    autoScroll: {
      speed: 0.5,
    },
  }).mount(window.splide.Extensions);
};

if ($('#product-categories').length > 0) {
  document.addEventListener('DOMContentLoaded', function () {
    var splide = new Splide('#product-categories', {
      gap: 32,
      pagination: false,
      rewind: true,
      perPage: 3,
      padding: { right: 250 },
      breakpoints: {
        992: {
          perPage: 2,
          padding: { right: 150 }
        },
        576: {
          perPage: 1,
          padding: { right: 100 }
        },
      },
    }).mount();
  });
}
  
if ($('#catalogs-slider').length > 0) {
  document.addEventListener('DOMContentLoaded', function () {
    var splide = new Splide('#catalogs-slider', {
      gap: 32,
      pagination: false,
      rewind: true,
      perPage: 3,
      padding: { right: 250 },
      breakpoints: {
        992: {
          perPage: 2,
          padding: { right: 150 }
        },
        576: {
          perPage: 1,
          padding: { right: 100 }
        },
      },
    }).mount();
  });
}


if ($('#big-image').length > 0) {
  document.addEventListener('DOMContentLoaded', function () {
      var main = new Splide('#big-image', {
          //type: 'loop',
          rewind: false,
          pagination: false,
          arrows: true,
      });

      var thumbnails = new Splide('#small-images', {
          fixedWidth: 100,
          gap: 24,
          rewind: false,
          pagination: false,
          arrows: false,
          isNavigation: true,
          breakpoints: {
              540: {
                  gap: 12,
              },
          },
      });

      main.sync(thumbnails);
      main.mount();
      thumbnails.mount();
  });
};
  
/**** SplideJS end ****/